export interface ApiErrorResponse {
  code?: ApiErrorCode
  reason?: string
  status: number
  message?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cause?: any
}

export enum ApiErrorCode {
  DATABASE = 'DatabaseError',
  BUSINESS = 'BusinessLogicError',
  UPSTREAM = 'UpstreamError',
  VALIDATION = 'ValidationError',
  UNKNOWN = 'UnknownError',
}

export enum ApiErrorReason {
  UNEXPECTED_DATABASE_ERROR = 'UnexpectedDatabaseError',
  USER_NOT_CONFIGURED = 'UserNotConfigured',
  USER_NOT_IDENTIFIED = 'UserNotIdentified',
  OIDC_USER_INFO_ERROR = 'OidcUserInfoError',
  NO_ACTIVE_CAMPAIGN_FOUND = 'NoActiveCampaign',
  TOO_MANY_ACTIVE_CAMPAIGNS = 'TooManyActiveCampaigns',
  CAMPAIGN_USER_NOT_FOUND = 'CampaignUserNotFound',
  CAMPAIGN_NOT_FOUND = 'CampaignNotFound',
  VALIDATION_ERROR = 'ValidationErrorSeeCauseForDetails',
  UNKNOWN_ERROR = 'UnknownError',
}
