import Api from './Api'
import { OpenidUserResponse, UserResponse } from '@/types/user'

export default {
  async getActiveUser() {
    const response = await Api().get<UserResponse>('/campaign/active/user/me')

    return response.data
  },
  async getOpenidUserInfo() {
    const response = await Api().get<OpenidUserResponse>('/user/me')

    return response.data
  },
  async updateOpenidUserInfo() {
    const response = await Api().put<OpenidUserResponse>('/user/me')

    return response.data
  },
  async getAllUsers() {
    const response = await Api().get<Array<OpenidUserResponse>>('/admin/users')

    return response.data
  },
}
