import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { useCampaignUserStore } from './campaignUser'
import axiosErrorHandler from '@/services/AxiosErrorHandler'
import { RootState } from '@/types/root'

export const useRootStore = defineStore('root', {
  state: (): RootState => ({
    isStoreFilled: false,
    error: null,
    redirectUrl: null,
    apiOperationCount: 0,
  }),
  getters: {
    loading: (state) => state.apiOperationCount > 0,
  },
  actions: {
    // TODO (charlie): Rename this to something more appropriate
    async checkStoreStatus() {
      if (this.isStoreFilled) return

      try {
        const userStore = useUserStore()
        const activeUserPromise = userStore.fetchAllDataForActiveUser()

        const campaignUserStore = useCampaignUserStore()
        const campaignUserPromise = campaignUserStore.fetchCampaignUsers()

        await Promise.all([activeUserPromise, campaignUserPromise])

        this.isStoreFilled = true
      } catch (err: unknown) {
        this.error = axiosErrorHandler.parseAxiosError(err)
      }
    },
    incrementLoadingOperations() {
      this.apiOperationCount = this.apiOperationCount + 1
    },
    decrementLoadingOperations() {
      if (this.apiOperationCount > 0) {
        this.apiOperationCount = this.apiOperationCount - 1
      }
    },
  },
})
