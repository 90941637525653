import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WelcomeMessage = _resolveComponent("WelcomeMessage")!
  const _component_FlowButton = _resolveComponent("FlowButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_WelcomeMessage, { class: "max-mobile:bg-white max-mobile:pb-32" }),
    _createVNode(_component_FlowButton, {
      class: "max-mobile:mb-6",
      text: "Sign in →",
      "data-testid": "sign-in-button",
      selectable: "",
      "mobile-fixed": true,
      onClick: _ctx.login
    }, null, 8, ["onClick"])
  ]))
}