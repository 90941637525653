import { defineStore } from 'pinia'
import { CampaignState, CampaignResponse } from '@/types/campaign'

export const useCampaignStore = defineStore('campaign', {
  state: (): CampaignState => ({
    id: undefined,
    name: '',
    description: '',
    startDate: undefined,
    endDate: undefined,
    isActive: undefined,
    daysLeft: undefined,
    daysToStart: undefined,
    status: undefined,
    participantCount: undefined,
    completeParticipantCount: undefined,
  }),

  actions: {
    updateCampaign(campaign: CampaignResponse) {
      this.id = campaign.id
      this.name = campaign.name
      this.description = campaign.description
      this.startDate = campaign.startDate
      this.endDate = campaign.endDate
      this.isActive = campaign.isActive
      this.daysLeft = campaign.daysLeft
      this.daysToStart = campaign.daysToStart
      this.participantCount = campaign.participantCount
      this.completeParticipantCount = campaign.completeParticipantCount
    },
  },
})
