
import { defineComponent } from 'vue'
import { Auth } from '@aws-amplify/auth'
import WelcomeMessage from '@/components/WelcomeMessage.vue'
import FlowButton from '@/components/FlowButton.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    WelcomeMessage,
    FlowButton,
  },
  methods: {
    login() {
      const returnUrl = this.$route.query.returnUrl

      const state = returnUrl
        ? JSON.stringify({
            pathName: returnUrl,
            nonce: crypto.randomUUID(),
          })
        : null

      const provider = window.location.hostname.includes('fivestardev.com') ? 'fivestar' : 'Google'

      Auth.federatedSignIn({
        customProvider: provider, // Federated identity provider sign-in in Cognito
        customState: state || undefined,
      })
    },
  },
})
