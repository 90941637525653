import { defineStore } from 'pinia'
import { CampaignUserList, AdminCampaignUserList, CampaignUserResponse, CampaignUserState } from '@/types/campaignUser'
import CampaignUserService from '@/services/CampaignUserService'

export const useCampaignUserStore = defineStore('campaignUser', {
  state: (): CampaignUserState => ({
    campaignUsers: [] as CampaignUserList,
    adminCampaignUsers: [] as AdminCampaignUserList,
  }),
  getters: {
    getFilteredCampaginUsers: (state) => (searchInput: string, ascending: boolean, departments: string[]) => {
      const filteredCampaignUsers = state.campaignUsers
        .filter((user) => user.displayName.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
        .filter((user) => {
          if (departments.length === 0) {
            return true
          } else {
            return departments.includes(user.department)
          }
        })
      const sortedCampaignUsers = ascending
        ? filteredCampaignUsers.sort((a, b) => a.displayName.localeCompare(b.displayName))
        : filteredCampaignUsers.sort((a, b) => b.displayName.localeCompare(a.displayName))
      return sortedCampaignUsers
    },
  },
  actions: {
    async fetchCampaignUsers() {
      const response = await CampaignUserService.getCampaignUsers()
      const payload: CampaignUserList = response.map((c: CampaignUserResponse) => ({
        campaignUserId: c.id,
        canReceive: c.canReceive,
        userId: c.user.id,
        uid: c.user.uid,
        firstName: c.user.firstName,
        lastName: c.user.lastName,
        displayName: `${c.user.firstName} ${c.user.lastName}`.trim() || c.user.email,
        email: c.user.email,
        avatarUrl: c.user.avatarUrl,
        department: c.user.department,
        leftToGive: c.user.leftToGive,
      }))

      this.campaignUsers = payload
    },
  },
})
