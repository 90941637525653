import { DirectiveBinding } from 'vue'

interface ClickOutsideElement extends HTMLElement {
  clickOutsideEvent: (event: MouseEvent) => void
}

const clickOutside = {
  beforeMount(el: ClickOutsideElement, binding: DirectiveBinding) {
    el.clickOutsideEvent = function (event: MouseEvent) {
      // Check if the click was outside the element
      if (!(el === event.target || el.contains(event.target as Node))) {
        // Call the provided method
        binding.value(event)
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el: ClickOutsideElement) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
}

export default clickOutside
