<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2444_968)">
      <path
        d="M4 2C2.89543 2 2 2.89543 2 4V13C2 14.1046 2.89543 15 4 15H7V11C7 8.79086 8.79086 7 11 7H15V4C15 2.89543 14.1046 2 13 2H4Z"
        fill="#004AB9"
      />
      <path
        d="M9 11C9 9.89543 9.89543 9 11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11Z"
        fill="#004AB9"
      />
    </g>
    <defs>
      <clipPath id="clip0_2444_968">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
