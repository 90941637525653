
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import { defineComponent } from 'vue'
import { useRootStore } from '@/stores/root'

export default defineComponent({
  name: 'LoadingOverlay',
  components: {
    Loading,
  },
  computed: {
    isLoading() {
      return useRootStore().loading
    },
  },
})
