
import { defineComponent } from 'vue'
import { Avatar } from 'flowbite-vue'
import BaseIcon from './BaseIcon.vue'

export default defineComponent({
  name: 'UserAvatar',
  components: {
    Avatar,
    BaseIcon,
  },
  props: {
    imageUrl: { type: String, default: '' },
  },
})
