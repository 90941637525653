import Api from '@/services/Api'
import { Contribution, ContributionResponse } from '@/types/contribution'

export default {
  async getMyContributions() {
    const response = await Api().get<ContributionResponse[]>('campaign/active/user/me/crew')

    return response.data
  },
  async addInitialContributions(initialCampaignUserIds: number[]) {
    const url = 'campaign/active/user/me/crew/'
    const response = await Api().post<number[]>(url, { initialCampaignUserIds: initialCampaignUserIds })
    return response.data
  },
  async addCrewMemberContribution(campaignUserId: number) {
    const url = `campaign/active/recipients/${campaignUserId}/contribution`
    const response = await Api().post<Contribution>(url)
    return response.data
  },
  async updateContribution(campaignUserId: number, amount: number, note: string) {
    const response = await Api().put<ContributionResponse>(
      `campaign/active/recipients/${campaignUserId}/contribution`,
      {
        amount: amount,
        note: note,
      },
    )

    return response.data
  },
  async deleteCampaignContribution(campaignUserId: number) {
    const url = `campaign/active/recipients/${campaignUserId}/contribution`
    const response = await Api().delete(url)

    return response.data
  },
}
