
import AppHeader from '@/components/AppHeader.vue' // @ is an alias to /src
import LoadingOverlay from './components/LoadingOverlay.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    LoadingOverlay,
    AppHeader,
  },
  data() {
    return {}
  },
  mounted() {
    const currentUrl = window.location.hostname
    if (currentUrl.includes('fivestardev.com')) {
      document.title = 'Fivestar* Contributr'
    } else if (currentUrl.includes('simplesoft.net')) {
      document.title = 'Simplesoft Contributr'
    } else {
      document.title = 'Ingage Contributr'
    }
  },
})
