<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.18121 1.99397C8.9895 0.593968 11.0102 0.593972 11.8185 1.99397L19.3529 15.044C20.1612 16.444 19.1509 18.194 17.5343 18.194H2.46544C0.848854 18.194 -0.161505 16.444 0.646784 15.044L8.18121 1.99397ZM9.99981 4.99998C10.4969 4.99998 10.8998 5.40292 10.8998 5.89998V10.1C10.8998 10.597 10.4969 11 9.99981 11C9.50275 11 9.0998 10.597 9.0998 10.1V5.89998C9.0998 5.40292 9.50275 4.99998 9.99981 4.99998ZM9.99981 15.8C10.6625 15.8 11.1998 15.2627 11.1998 14.6C11.1998 13.9372 10.6625 13.4 9.99981 13.4C9.33706 13.4 8.7998 13.9372 8.7998 14.6C8.7998 15.2627 9.33706 15.8 9.99981 15.8Z"
      fill="#EAB308"
    />
  </svg>
</template>
