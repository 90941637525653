import UserService from '@/services/UserService'
import { UserState } from '@/types/user'
import { defineStore } from 'pinia'
import { useCampaignStore } from './campaign'
import { useContributionStore } from './contribution'

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    userId: undefined,
    uid: '',
    firstName: '',
    lastName: '',
    email: '',
    avatarUrl: '',
    department: '',
    canGive: undefined,
    canReceive: undefined,
    leftToGive: undefined,
    totalGiven: undefined,
    amountToGive: undefined,
    groups: undefined,
  }),
  getters: {
    displayName: (state) => `${state.firstName} ${state.lastName}`.trim() || state.email,
    isAdmin: (state) => {
      // TODO: make this more robust
      const tenant = window.location.hostname.includes('fivestardev.com') ? 'fivestar' : 'ingage'
      return state.groups && state.groups.includes(`urn:contributr:${tenant}:admin`)
    },
  },
  actions: {
    async fetchAllDataForActiveUser() {
      const response = await UserService.getActiveUser()

      const user = response.user
      if (user.id !== -1) {
        this.userId = user.id
        this.uid = user.uid
        this.firstName = user.firstName
        this.lastName = user.lastName
        this.email = user.email
        this.avatarUrl = user.avatarUrl
        this.department = user.department
        this.groups = user.groups
      } else {
        const nonCampaignUser = await UserService.getOpenidUserInfo()
        this.userId = nonCampaignUser.id
        this.uid = nonCampaignUser.uid
        this.firstName = nonCampaignUser.firstName
        this.lastName = nonCampaignUser.lastName
        this.email = nonCampaignUser.email
        this.avatarUrl = nonCampaignUser.avatarUrl
        this.department = nonCampaignUser.department
        this.groups = nonCampaignUser.groups
      }

      this.canGive = response.canGive
      this.canReceive = response.canReceive
      this.leftToGive = response.leftToGive
      this.totalGiven = response.totalGiven
      this.amountToGive = response.amountToGive

      const campaignResponse = response.campaign
      const contributionResponse = response.contributions

      const campaignStore = useCampaignStore()
      campaignStore.updateCampaign(campaignResponse)

      const contributionStore = useContributionStore()
      contributionStore.updateContributions(contributionResponse)
    },
  },
})
