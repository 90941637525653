import { ConfigOptions } from '@/types/configuration'
import axios from 'axios'

const configurationAlreadyInitialized = new Error('Configuration has already been initialized')

export default class ConfigService {
  private static instance: ConfigOptions

  public static async initializeAsync(): Promise<ConfigOptions> {
    if (ConfigService.instance != null) {
      throw configurationAlreadyInitialized
    }

    const results = await axios.get<ConfigOptions>('/config.json')
    ConfigService.instance = results.data
    return ConfigService.instance
  }

  public static get(): ConfigOptions {
    return ConfigService.instance
  }
}
