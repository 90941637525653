
import { defineComponent } from 'vue'
/**
 * Please keep the icon objects in alphabetical order
 * -- jnat01
 */
import ArrowsUpDownIcon from './icons/ArrowsUpDownIcon.vue'
import CheckmarkIcon from './icons/CheckmarkIcon.vue'
import ChevronDown from './icons/ChevronDown.vue'
import ChevronIcon from './icons/ChevronIcon.vue'
import ChevronRightIcon from './icons/ChevronRightIcon.vue'
import CircleXIcon from './icons/CircleXIcon.vue'
import CurrencyDollarIcon from './icons/CurrencyDollarIcon.vue'
import EllipsisVerticalIcon from './icons/EllipsisVerticalIcon.vue'
import ErrorIcon from './icons/ErrorIcon.vue'
import FunnelIcon from './icons/FunnelIcon.vue'
import MagnifyingGlass from './icons/MagnifyingGlassIcon.vue'
import PencilIcon from './icons/PencilIcon.vue'
import UserCircleIcon from './icons/UserCircleIcon.vue'
import WarningTriangle from './icons/WarningTriangle.vue'
import TrashIcon from './icons/TrashIcon.vue'
import LeftArrow from './icons/LeftArrow.vue'
import RightArrow from './icons/RightArrow.vue'
import FeedbackIcon from './icons/FeedbackIcon.vue'
import RedCircleXIcon from './icons/RedCircleXIcon.vue'
import NavigationSquares from './icons/NavigationSquares.vue'
import GiftIcon from '@/components/icons/GiftIcon.vue'
import CalenderIcon from '@/components/icons/CalenderIcon.vue'
import CircleInfo from './icons/CircleInfo.vue'
import UploadFile from './icons/UploadFile.vue'
import ArrowRight from '@/components/icons/ArrowRight.vue'

/**
 * Please keep the icon objects in alphabetical order
 * -- jnat01
 */
const icons = {
  arrowsUpDown: ArrowsUpDownIcon,
  checkmark: CheckmarkIcon,
  chevron: ChevronIcon,
  chevronDown: ChevronDown,
  chevronRight: ChevronRightIcon,
  circleX: CircleXIcon,
  currencyDollar: CurrencyDollarIcon,
  ellipsisVertical: EllipsisVerticalIcon,
  error: ErrorIcon,
  funnel: FunnelIcon,
  magnifyingGlass: MagnifyingGlass,
  pencil: PencilIcon,
  userCircle: UserCircleIcon,
  warningTriangle: WarningTriangle,
  trashCan: TrashIcon,
  redCircleX: RedCircleXIcon,
  leftArrow: LeftArrow,
  rightArrow: RightArrow,
  feedbackIcon: FeedbackIcon,
  navSquares: NavigationSquares,
  giftIcon: GiftIcon,
  calendarIcon: CalenderIcon,
  circleInfo: CircleInfo,
  uploadFile: UploadFile,
  arrowRight: ArrowRight,
}

export default defineComponent({
  props: {
    tailwindClass: { type: String, default: '' },
    name: {
      type: String,
      required: true,
      validator(value: PropertyKey) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      },
    },
  },
  computed: {
    iconComponent() {
      const name = this.name as keyof typeof icons
      return icons[name]
    },
  },
})
