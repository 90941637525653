
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WelcomeMessage',
  data(): {
    message: string
    stepsOverview: Array<{
      number: number
      description: string
      imageName: string
    }>
  } {
    return {
      message:
        'We’re here to help you give back to teammates who have helped, supported, or encouraged you in some way this year. Maybe they’re just a joy to be around. It’s your chance to tell them.',
      stepsOverview: [
        {
          number: 1,
          description: 'Draft your crew',
          imageName: 'draft-crew',
        },
        {
          number: 2,
          description: 'Write a little note',
          imageName: 'write-note',
        },
        {
          number: 3,
          description: 'Perhaps, give them some cash',
          imageName: 'give-cash',
        },
      ],
    }
  },
})
