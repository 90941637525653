import { Auth } from '@aws-amplify/auth'
import axios, { AxiosHeaders } from 'axios'
import configuration from './ConfigService'
import { useRootStore } from '@/stores/root'

export default () => {
  const client = axios.create({
    baseURL: configuration.get().apiBaseUrl,
  })

  client.interceptors.request.use(
    (config) => {
      useRootStore().incrementLoadingOperations()
      return config
    },
    (err) => {
      useRootStore().decrementLoadingOperations()
      throw err
    },
  )

  client.interceptors.response.use(
    (res) => {
      useRootStore().decrementLoadingOperations()
      return res
    },
    (err) => {
      useRootStore().decrementLoadingOperations()
      throw err
    },
  )

  client.interceptors.request.use(async (config) => {
    try {
      await Auth.currentSession().then((sessionData) => {
        if (sessionData.isValid()) {
          if (config.headers === undefined) {
            config.headers = new AxiosHeaders()
          }
          ;(config.headers as AxiosHeaders).set('Authorization', `Bearer ${sessionData.getAccessToken().getJwtToken()}`)
        }
      })
    } catch (err) {
      // TODO: What's our standard for handling errors, etc? I assume NOT console.log...
      console.log(`No user session: ${JSON.stringify(err)}`)
    }
    return config
  })

  return client
}
