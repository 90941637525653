import { Hub, HubCapsule } from '@aws-amplify/core'
import { Router } from 'vue-router'
import UserService from '@/services/UserService'
import { useRootStore } from '@/stores/root'
import { useContributionStore } from '@/stores/contribution'

export default {
  registerAuthListener(router: Router) {
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          await this.handleSigninEvent(router)
          break
        case 'customOAuthState':
          await this.handleCustomOauthState(data)
          break
      }
    })
  },
  async handleSigninEvent(router: Router) {
    // After signing in, update the current user's info from OIDC userinfo
    await UserService.updateOpenidUserInfo()

    // Fill the data store if not done already
    const rootStore = useRootStore()
    await rootStore.checkStoreStatus()

    // Check for redirect, then contributions, and redirect appropriately
    const redirect = rootStore.redirectUrl
    if (redirect !== null) {
      rootStore.redirectUrl = null
      router.push(redirect)
    } else {
      const contributionStore = useContributionStore()
      const contributions = contributionStore.contributions

      // If there are no contributions, or none of the contributions have any amount or note,
      // we want to land on the draft-crew page so we can select more people to add to your crew
      if (
        !contributions ||
        contributions.length == 0 ||
        contributions.every((c) => {
          return c.amount <= 0 && c.note?.trim().length == 0
        })
      ) {
        router.push('/draft-crew')
      } else {
        router.push('/your-crew')
      }
    }
  },
  async handleCustomOauthState(data: HubCapsule) {
    const customStateString = data.payload.data
    let targetUrl: string | null = null
    if (customStateString) {
      const customState = JSON.parse(customStateString)
      targetUrl = customState['pathName']
    }

    if (targetUrl !== null) {
      // Since the customOAuthState event fires before the signIn event, we can't simply
      // do a router.push(targetUrl) here, because it will get overridden by the /your-crew
      // or /draft-crew router.push in signin.
      //
      // So instead, we'll put the redirect URL into the root state, then check for it in
      // the signIn event, and skip the /your-crew & /draft-crew logic in that event handler.
      const rootStore = useRootStore()
      rootStore.redirectUrl = targetUrl
    }
  },
}
