import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import '@/assets/styles/tailwind.css'
import { Auth } from '@aws-amplify/auth'
import configService from '@/services/ConfigService'
import clickOutside from '@/directives/clickOutside'

configService.initializeAsync().then((config) => {
  const baseUrl =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '')
  config.auth.oauth.redirectSignIn = baseUrl + '/oauth2/idpresponse'
  config.auth.oauth.redirectSignOut = baseUrl + '/oauth2/idpresponse'

  Auth.configure(config.auth)
  const pinia = createPinia()
  const app = createApp(App)
  app.directive('click-outside', clickOutside)
  app.use(pinia).use(router).mount('#app')
})
