import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SignIn from '@/views/SignIn.vue'
import { Auth } from '@aws-amplify/auth'
import AwsAmplifyService from '@/services/AwsAmplifyService'
import { ApiErrorReason } from '@/types/apiErrorResponse'
import { useRootStore } from '@/stores/root'
import { useUserStore } from '@/stores/user'

/**
 * Route level code-splitting for the component via webpackChunkName.
 * This generates a separate chunk (route.[hash].js) for the route
 * which is lazy-loaded when the route is visited.
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'sign-in',
    meta: { allowUnauthenticated: true },
    component: SignIn,
  },
  {
    path: '/no-active-campaign',
    name: 'no-active-campaign',
    component: () => import(/* webpackChunkName: "no-active-campaign" */ '@/views/NoActiveCampaign.vue'),
  },
  {
    path: '/draft-crew',
    name: 'draft-crew',
    component: () => import(/* webpackChunkName: "draftcrew" */ '@/views/DraftCrew.vue'),
  },
  {
    path: '/your-crew',
    name: 'your-crew',
    component: () => import(/* webpackChunkName: "your-crew" */ '@/views/YourCrew.vue'),
  },
  {
    path: '/give-complete',
    name: 'give-complete',
    component: () => import(/* webpackChunkName: "your-crew" */ '@/views/GiveComplete.vue'),
  },
  {
    path: '/admin/campaigns',
    name: 'admin-campaigns',
    meta: { requiresAdmin: true },
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/views/admin/AdminCampaigns.vue'),
  },
  {
    path: '/admin/manage-campaign/:id',
    name: 'manage-campaign',
    meta: { requiresAdmin: true },
    component: () => import(/* webpackChunkName: "manage-campaign" */ '@/views/admin/AdminManageCampaign.vue'),
  },
  {
    path: '/admin/edit-campaign/:id?',
    name: 'edit-campaign',
    meta: { requiresAdmin: true },
    component: () => import(/* webpackChunkName: "edit-campaign" */ '@/views/admin/AdminCreateEditCampaign.vue'),
  },
  {
    path: '/admin/comments-report/:id?',
    name: 'comments-report',
    meta: { requiresAdmin: true },
    component: () => import(/* webpackChunkName: "comments-report" */ '@/views/admin/AdminCommentsReport.vue'),
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "no-active-campaign" */ '@/views/admin/Unauthorized.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to) => {
  // TODO: Do we need to do anything to support refreshing (or directly calling a authenticated page) and history mode?
  if (to.meta.allowUnauthenticated !== true) {
    try {
      await Auth.currentAuthenticatedUser()
    } catch (ex) {
      console.log('User is not authenticated, redirecting to home page', ex)
      // Don't want to set the returnUrl (for deep-linking after login) if it is one of the oauth2 URLs
      if (!to.fullPath.startsWith('/oauth')) {
        return { name: 'sign-in', query: { returnUrl: to.fullPath } }
      } else {
        return { name: 'sign-in' }
      }
    }

    const rootStore = useRootStore()
    await rootStore.checkStoreStatus()

    // TODO: We probably should consider other errors in here, but do we need a separate error page for those?
    //       There is some information in the ApiErrorResponse object that could be used to give the user some
    //       helpful information, possibly...
    const error = rootStore.error
    if (error !== null) {
      if (
        error.reason === ApiErrorReason.NO_ACTIVE_CAMPAIGN_FOUND ||
        error.reason === ApiErrorReason.TOO_MANY_ACTIVE_CAMPAIGNS ||
        error.reason === ApiErrorReason.CAMPAIGN_USER_NOT_FOUND
      ) {
        // Need to check the destination route, so that we don't infinitely recurse...
        if (to.name !== 'no-active-campaign') {
          return { name: 'no-active-campaign' }
        }
      }
    }
  }

  const userStore = useUserStore()
  // for any routes that require admin permission, add meta: { requiresAdmin: true },
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!userStore.isAdmin) {
      return { name: 'unauthorized' }
    }
  }
})

AwsAmplifyService.registerAuthListener(router)

export default router
