import { defineStore } from 'pinia'
import { Contribution, ContributionList, ContributionResponse, ContributionState } from '@/types/contribution'
import { useUserStore } from './user'
import ContributionService from '@/services/ContributionService'

export const useContributionStore = defineStore('contribution', {
  state: (): ContributionState => ({
    contributions: [] as ContributionList,
    currentContribution: {} as Contribution,
  }),
  getters: {
    getFilteredContributions: (state) => (searchInput: string, ascending: boolean, departments: string[]) => {
      const filteredContributions = state.contributions
        .filter((contribution) => {
          return contribution.displayName.toLowerCase().includes(searchInput.toLowerCase())
        })
        .filter((contribution) => {
          if (departments.length === 0) {
            return true
          } else {
            return departments.includes(contribution.department)
          }
        })
      const sortedContributions =
        ascending === true
          ? filteredContributions.sort((a, b) => a.displayName.localeCompare(b.displayName))
          : filteredContributions.sort((a, b) => b.displayName.localeCompare(a.displayName))
      return sortedContributions
    },
    isStartedGiving(state) {
      const started =
        state.contributions.length > 0 &&
        state.contributions.some((c) => {
          return c.amount > 0 || c.note?.trim().length > 0
        })
      return started
    },
    totalGiven(): number {
      return this.contributions.reduce((total, contribution) => total + contribution.amount, 0)
    },
    totalGivenIncludingCurrent(): number {
      return this.contributions.reduce(
        (total: number, contribution: Contribution): number =>
          // TODO: We need the truthy test on this.currentContribution.amount since it can be empty string
          // which then concatenates the strings for total. Re-evaluate if we move to non-input control
          contribution.contributionId == this.currentContribution.contributionId
            ? (this.currentContribution.amount ? this.currentContribution.amount : 0) + total
            : contribution.amount + total,
        0,
      )
    },
    totalRemainingIncludingCurrent(): number {
      const userStore = useUserStore()
      if (userStore.amountToGive == null) return 0
      return userStore.amountToGive - this.totalGivenIncludingCurrent
    },
  },
  actions: {
    updateContributions(response: Array<ContributionResponse>) {
      const payload: ContributionList = response.map((c: ContributionResponse) => ({
        contributionId: c.id,
        amount: c.amount,
        note: c.note,
        isComplete: c.isComplete,
        campaignUserId: c.givenTo.id,
        userId: c.givenTo.user.id,
        firstName: c.givenTo.user.firstName,
        lastName: c.givenTo.user.lastName,
        email: c.givenTo.user.email,
        avatarUrl: c.givenTo.user.avatarUrl,
        department: c.givenTo.user.department,
        displayName: `${c.givenTo.user.firstName} ${c.givenTo.user.lastName}`.trim() || c.givenTo.user.email,
      }))

      this.contributions = payload
    },

    async updateContribution(contribution: Contribution) {
      if (!this.hasChanged()) {
        return
      }

      await ContributionService.updateContribution(contribution.campaignUserId, contribution.amount, contribution.note)
      const userStore = useUserStore()
      await userStore.fetchAllDataForActiveUser()
    },

    hasChanged(): boolean {
      const current = this.currentContribution
      if (!current) {
        return false
      }

      const saved = this.contributions.find(
        (contribution) => contribution.contributionId === this.currentContribution.contributionId,
      )
      return current.amount != saved?.amount || current.note != saved.note
    },

    /**
     * GET - only retrieving the contributions from the current user within the active campaign
     * and then committing the response to the Contributions state module.
     */
    async fetchMyContributions() {
      const response = await ContributionService.getMyContributions()
      this.updateContributions(response)
    },

    /**
     * POST - adding a contribution for a campaign user and then fetching the updated
     * contributions of the current user within the active campaign and updating
     * the state module accordingly.
     *
     * @param campaignUserId - the CampaignUser ID that the current user is contributing to
     */
    async addCrewMemberContribution(campaignUserId: number) {
      await ContributionService.addCrewMemberContribution(campaignUserId)
      await this.fetchMyContributions()
    },
    async deleteCampaignContribution(campaignUserId: number) {
      try {
        await ContributionService.deleteCampaignContribution(campaignUserId)
        await this.fetchMyContributions()
      } catch (error) {
        console.error(error)
        throw new Error('Failed to delete contribution')
      }
    },

    async addInitialCrewMemberContributions(contributionUserIds: number[]) {
      await ContributionService.addInitialContributions(contributionUserIds)
      await this.fetchMyContributions()
    },
  },
})
