import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    selectable: _ctx.selectable,
    disabled: !_ctx.selectable,
    class: _normalizeClass(`${
      _ctx.mobileFixed ? 'max-mobile:bottom-6 max-mobile:right-16 max-mobile:fixed max-mobile:z-50' : ''
    } bottom-1 ${_ctx.buttonColor} ${_ctx.isSelectable} ${_ctx.tailwindClass}`),
    color: _ctx.buttonColor,
    outline: _ctx.outline
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["selectable", "disabled", "class", "color", "outline"]))
}