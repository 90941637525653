
import { defineComponent } from 'vue'
import { Button } from 'flowbite-vue'

export default defineComponent({
  name: 'FlowButton',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
  },
  props: {
    mobileFixed: { type: Boolean, default: false },
    tailwindClass: { type: String, default: '' },
    color: { type: String, default: '' },
    text: { type: String, required: true },
    selectable: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
  },
  computed: {
    buttonColor() {
      if (this.color) return `${this.color}`
      return 'default'
    },
    isSelectable() {
      if (this.selectable) return 'opacity-100'
      return 'opacity-50 cursor-not-allowed'
    },
  },
})
