import { ApiErrorCode, ApiErrorReason, ApiErrorResponse } from '@/types/apiErrorResponse'
import { AxiosError } from 'axios'

export default {
  parseAxiosError(err: unknown): ApiErrorResponse {
    let errResponse: ApiErrorResponse | null = null

    if (err instanceof AxiosError) {
      const axiosError = err as AxiosError
      errResponse = axiosError.response?.data as ApiErrorResponse | null
    }

    if (errResponse == null) {
      return <ApiErrorResponse>{
        status: 500,
        code: ApiErrorCode.UNKNOWN,
        reason: ApiErrorReason.UNKNOWN_ERROR,
        message: `Call failed - ${JSON.stringify(err)}`,
      }
    } else {
      return errResponse
    }
  },
}
