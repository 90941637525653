import Api from '@/services/Api'
import { CampaignUserResponse } from '@/types/campaignUser'

export default {
  async getCampaignUsers() {
    const response = await Api().get<CampaignUserResponse[]>('/campaign/active/recipients')

    return response.data
  },
}
